import { Button } from '@arckit/ui'
import axios from 'axios'
import Layout from 'components/Layout'
import { Col, Row, Section } from 'components/grid'
import React, { useEffect, useState } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { wait } from 'utils/wait'
import { Deploy } from 'api/netlify/deploys'
import { twMerge } from 'tailwind-merge'
import { format, isToday, isYesterday } from 'date-fns'
import Skeleton from 'react-loading-skeleton'

const auth_token = process.env.GATSBY_EXTERNAL_AUTH_TOKEN as string
const axiosConfig = { headers: { Authorization: auth_token } }

export default function BuildPage() {
	const [loading, setLoading] = useState(false)
	const [loading2, setLoading2] = useState(false)
	const [deploys, setDeploys] = useState<{ prod: Deploy[]; dev: Deploy[] }>({ prod: [], dev: [] })

	useEffect(() => {
		async function getDeploys() {
			try {
				const { data } = await axios.get<{ prod: Deploy[]; dev: Deploy[] }>('/api/netlify/deploys', axiosConfig)
				setDeploys(data)
			} catch (error: any) {
				if (axios.isAxiosError(error)) {
					toast.error(error.response?.data)
				} else toast.error(error.message)
			}
		}
		getDeploys()
	}, [])

	async function triggerProdBuild() {
		try {
			setLoading(true)
			await axios.post('https://api.netlify.com/build_hooks/64fa044bc89beb25bfaaf02a?trigger_title=Triggered+from+website+dashboard')
			await wait(1000)
			setLoading(false)
			toast.success('Production Build Triggered')
			await wait(2000)
			window.location.reload()
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				toast.error(error.response?.data)
			} else toast.error(error.message)
		}
	}
	async function triggerDevBuild() {
		try {
			setLoading2(true)
			axios.post('hhttps://api.netlify.com/build_hooks/64fa0459e0da2b2772af780f?trigger_title=Triggered+from+website+dashboard')
			await wait(1000)
			setLoading2(false)
			toast.success('Development Build Triggered')
			await wait(2000)
			window.location.reload()
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				toast.error(error.response?.data)
			} else toast.error(error.message)
		}
	}

	return (
		<Layout>
			<Section margin="mt-20">
				<Row>
					<Col width="w-1/2" className="flex flex-col">
						<div className="mb-2 flex items-center gap-2">
							<h2>Production</h2>
							<a href="https://app.netlify.com/sites/arc-react/deploys">
								<img src="https://api.netlify.com/api/v1/badges/232cc184-c3e1-45e4-8b8d-36f55af870be/deploy-status" alt="Netlify Status" />
							</a>
						</div>
						<div className="mb-4">
							<Button loading={loading} onClick={triggerProdBuild}>
								Build Production
							</Button>
						</div>
						<div>
							{deploys.prod.length > 0 ? (
								deploys.prod.map((d) => {
									return <DeployCard key={d.id} deploy={d} />
								})
							) : (
								<>
									<DeployCardSkelly />
									<DeployCardSkelly />
									<DeployCardSkelly />
									<DeployCardSkelly />
									<DeployCardSkelly />
								</>
							)}
						</div>
					</Col>
					<Col width="w-1/2" className="flex flex-col">
						<div className="mb-2 flex items-center gap-2">
							<h2>Development</h2>
							<a href="https://app.netlify.com/sites/arc-react/deploys">
								<img src="https://api.netlify.com/api/v1/badges/232cc184-c3e1-45e4-8b8d-36f55af870be/deploy-status?branch=development" alt="Netlify Status" />
							</a>
						</div>
						<div className="mb-4">
							<Button loading={loading2} onClick={triggerDevBuild}>
								Build Development
							</Button>
						</div>
						<div>
							{deploys.dev.length > 0 ? (
								deploys.dev.map((d) => {
									return <DeployCard key={d.id} deploy={d} />
								})
							) : (
								<>
									<DeployCardSkelly />
									<DeployCardSkelly />
									<DeployCardSkelly />
									<DeployCardSkelly />
									<DeployCardSkelly />
								</>
							)}
						</div>
					</Col>
				</Row>
			</Section>
			<Toaster />
		</Layout>
	)
}

interface DeployCardProps {
	deploy: Deploy
}

function DeployCard({ deploy }: DeployCardProps) {
	return (
		<div className="mb-4 w-full rounded border px-4 py-2">
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-2">
					<span className="text-lg font-semibold capitalize">{deploy.context}</span>
					<span className={twMerge('px-2 py-1 text-xs font-semibold capitalize', determineDeployStateBadgeColor(deploy.state))}>{deploy.state}</span>
				</div>
				<div>
					<span className="text-sm font-semibold">{dateFormat(deploy.created_at)}</span>
				</div>
			</div>
			<span className="block text-sm text-gray-500">{deploy.title}</span>
		</div>
	)
}

function DeployCardSkelly() {
	return (
		<div className="mb-4 w-full rounded border px-4 py-2">
			<div className="mb-1 flex items-center justify-between">
				<div className="flex items-center gap-2">
					<Skeleton height={20} width={175} />
					<Skeleton height={20} width={50} />
				</div>
				<div>
					<Skeleton width={120} />
				</div>
			</div>
			<Skeleton height={12} />
		</div>
	)
}

function determineDeployStateBadgeColor(state: Deploy['state']) {
	if (state === 'building') return 'bg-yellow-100 text-yellow-700'
	if (state === 'ready') return 'bg-green-100 text-green-700'
	if (state === 'failed' || state === 'error') return 'bg-red-100 text-red-700'
	if (state === 'enqueued') return 'bg-blue-100 text-blue-700'
}

function dateFormat(date: string) {
	if (isToday(new Date(date))) return `Today at ${format(new Date(date), 'h:mm a')}`
	if (isYesterday(new Date(date))) return `Yesterday at ${format(new Date(date), 'h:mm a')}`
	else return format(new Date(date), 'LLL do h:mm a')
}
